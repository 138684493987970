import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="video-container">
      <video className="fullscreen-video vedio-in" autoPlay loop muted width="100%">
        <source src="https://gyani-fe.s3.ap-south-1.amazonaws.com/videos/SOON.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default ComingSoon;
